var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ready
    ? _c(
        "v-container",
        { staticClass: "invoice-wrapper" },
        [
          _c(
            "v-layout",
            {
              ref: "invoice",
              staticClass: "panel-wrapper invoice",
              attrs: { wrap: "" }
            },
            [
              _c(
                "v-flex",
                { staticClass: "left-sidebar", attrs: { xs3: "" } },
                [
                  _c("div", { staticClass: "logo-part" }, [
                    _c("img", {
                      attrs: { src: require("./invoice-13.png"), alt: "logo" }
                    })
                  ]),
                  _c(
                    "div",
                    { staticClass: "content-part pa-2" },
                    [
                      _vm._l(_vm.leftData, function(item, idx) {
                        return [
                          _c("h2", { key: idx }, [_vm._v(_vm._s(item.title))]),
                          _c("p", {
                            key: idx,
                            domProps: { innerHTML: _vm._s(item.text) }
                          })
                        ]
                      })
                    ],
                    2
                  )
                ]
              ),
              _c(
                "v-flex",
                { staticClass: "invoice-content", attrs: { xs9: "" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "pay-status",
                      class: _vm.statusClass(_vm.invoice.status)
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" + _vm._s(_vm.invoice.status) + "\n\t\t\t"
                      )
                    ]
                  ),
                  _c("h1", { staticClass: "pl-3" }, [
                    _vm._v("INVOICE #" + _vm._s(this.invoice.id))
                  ]),
                  _c("div", { staticClass: "box-body pa-3" }, [
                    _c("h2", [_vm._v("Invoice Items")]),
                    _c(
                      "table",
                      { staticClass: "table-bordered table-striped" },
                      [
                        _c(
                          "tbody",
                          [
                            _c("tr", [
                              _c("th"),
                              _c("th", [_vm._v("Description")]),
                              _c("th", [_vm._v("Amount")])
                            ]),
                            _vm._l(_vm.invoice.invoice_items, function(
                              item,
                              index
                            ) {
                              return _c("tr", { key: index }, [
                                _c("td", [_vm._v("0" + _vm._s(index + 1))]),
                                _c("td", {
                                  staticStyle: { "text-align": "left" },
                                  domProps: {
                                    innerHTML: _vm._s(item.description)
                                  }
                                }),
                                _c("th", [
                                  _vm._v("$ " + _vm._s(item.amount) + " ")
                                ])
                              ])
                            }),
                            _vm._l(_vm.invoiceItem, function(item, index) {
                              return [
                                item.show
                                  ? _c("tr", { key: index }, [
                                      _c("td"),
                                      _c(
                                        "td",
                                        {
                                          staticClass: "text-right",
                                          staticStyle: { "text-align": "right" }
                                        },
                                        [_vm._v(_vm._s(item.title))]
                                      ),
                                      _c("th", [_vm._v(_vm._s(item.text))])
                                    ])
                                  : _vm._e()
                              ]
                            })
                          ],
                          2
                        )
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "box-body pa-3" }, [
                    _c("h2", [_vm._v("Transcations")]),
                    _c(
                      "table",
                      {
                        staticClass: "table table-bordered table-striped",
                        attrs: { cellspacing: "0", cellpadding: "0" }
                      },
                      [
                        _c(
                          "tbody",
                          [
                            _c("tr", [
                              _c("th", [_vm._v("Transaction Date")]),
                              _c("th", [_vm._v("Gateway")]),
                              _c("th", [_vm._v("Transaction ID")]),
                              _c("th", [_vm._v("Amount")])
                            ]),
                            _vm.invoice.invoice_transactions.length
                              ? _vm._l(
                                  _vm.invoice.invoice_transactions,
                                  function(trans, index) {
                                    return _c("tr", { key: index }, [
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formatDate(
                                              trans.created,
                                              "MM/DD/YYYY HH:mm"
                                            )
                                          )
                                        )
                                      ]),
                                      _c("td", [
                                        _vm._v(_vm._s(trans.payment_method))
                                      ]),
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "word-break": "break-word"
                                          }
                                        },
                                        [_vm._v(_vm._s(trans.transaction_ID))]
                                      ),
                                      _c("td", [
                                        _vm._v(
                                          "$ " + _vm._s(trans.amount) + " USD"
                                        )
                                      ])
                                    ])
                                  }
                                )
                              : [
                                  _c("tr", [
                                    _c(
                                      "td",
                                      {
                                        staticClass: "text-center",
                                        attrs: { colspan: "4" }
                                      },
                                      [_vm._v("No Related Transaction Found")]
                                    )
                                  ])
                                ],
                            _c("tr", [
                              _c("td"),
                              _c("td"),
                              _c(
                                "td",
                                { staticStyle: { "text-align": "right" } },
                                [_vm._v("Balance")]
                              ),
                              _c("th", [
                                _vm._v("$ " + _vm._s(_vm.invoice.balance))
                              ])
                            ])
                          ],
                          2
                        )
                      ]
                    )
                  ])
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "paybuttons",
              staticStyle: { "text-align": "center" }
            },
            [
              _vm.invoice.status === "Unpaid"
                ? [
                    _c(
                      "v-btn",
                      {
                        staticClass: "my-3 primary text-none",
                        staticStyle: { width: "200px", height: "40px" },
                        on: { click: _vm.gotopayment }
                      },
                      [_vm._v("Pay Now")]
                    )
                  ]
                : _vm._e(),
              _c("i", {
                staticClass: "fa fa-print",
                attrs: { title: "Print" },
                on: { click: _vm.print }
              }),
              _c("i", {
                staticClass: "fa fa-download",
                attrs: { title: "Save as PDF" },
                on: { click: _vm.savePDF }
              })
            ],
            2
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }