<template>
	<v-container class="invoice-wrapper" v-if="ready">		
		<v-layout ref="invoice" wrap class="panel-wrapper invoice">
			<v-flex xs3 class="left-sidebar">
				<div class="logo-part">
					<img src="./invoice-13.png" alt="logo">
				</div>
				<div class="content-part pa-2">
					<template v-for=" (item ,idx) in leftData">
						<h2 :key="idx">{{item.title}}</h2>
						<p v-html="item.text" :key="idx"></p>
					</template>
				</div>
			</v-flex>
			<v-flex xs9 class="invoice-content">
				<div :class="statusClass(invoice.status)" class="pay-status">
					{{invoice.status}}
				</div>
				<h1 class="pl-3">INVOICE #{{this.invoice.id}}</h1>
				<div class="box-body pa-3">
					<h2>Invoice Items</h2>
					<table class="table-bordered table-striped">
						<tbody>
							<tr>
								<th></th>
								<th>Description</th>
								<th>Amount</th>
							</tr>
							<tr v-for="(item , index) in invoice.invoice_items" :key="index">
								<td>0{{index+1}}</td>
								<td v-html="item.description" style="text-align: left;"></td>
								<th>$ {{item.amount}} </th>
							</tr>
							<template v-for="(item,index) in invoiceItem">
								<tr v-if="item.show" :key="index">
									<td></td>
									<td class="text-right" style="text-align: right;">{{item.title}}</td>
									<th>{{item.text}}</th>
								</tr>
							</template>
						</tbody>
					</table>
				</div>
				<div class="box-body pa-3">
					<h2>Transcations</h2>
					<table class="table table-bordered table-striped" cellspacing="0" cellpadding="0">
						<tbody>
							<tr>
								<th>Transaction Date</th>
								<th>Gateway</th>
								<th>Transaction ID</th>
								<th>Amount</th>
							</tr>
							<template v-if="invoice.invoice_transactions.length">
								<tr v-for="(trans,index) in invoice.invoice_transactions" :key="index">
									<td>{{formatDate(trans.created,'MM/DD/YYYY HH:mm')}}</td>
									<td>{{trans.payment_method}}</td>
									<td style="word-break: break-word;">{{trans.transaction_ID}}</td>
									<td>$ {{trans.amount}} USD</td>
								</tr>
							</template>
							<template v-else>
								<tr>
									<td colspan="4" class="text-center">No Related Transaction Found</td>
								</tr>
							</template>
							<tr>
								<td></td>
								<td></td>
								<td style="text-align: right;">Balance</td>
								<th>$ {{invoice.balance}}</th>
							</tr>
						</tbody>
					</table>
				</div>
			</v-flex>
		</v-layout>
		<div class="paybuttons" style="text-align: center;">			
			<template v-if="invoice.status === 'Unpaid'">
				<v-btn class="my-3 primary text-none" @click="gotopayment" style="width: 200px;height: 40px;">Pay Now</v-btn>				
			</template>
			<i class="fa fa-print" @click="print" title="Print"></i>
			<i class="fa fa-download" @click="savePDF" title="Save as PDF"></i>
		</div>
	</v-container>
</template>
<script>
	import $ from 'jquery'
	import html2Canvas from 'html2canvas'
	import jsPDF from 'jspdf'
	import {
		formatDate
	} from '@/module/utils/date'

	export default {
		name: "InvoiceDetail",
		data() {
			return {
				ready: false,
				invoice: {}
			}
		},
		computed: {
			statusClass() {
				return (status) => {
					return status ? status.toLowerCase() : ''
				}
			},
			leftData() {
				if (!this.invoice.id) {
					return []
				}
				let arr = [{
						title: 'Pay To:',
						text: this.invoice.pay_to
					},
					{
						title: 'Bill To:',
						text: this.invoice.invoiced_to
					},					
					{
						title: 'Invoice Date:',
						text: formatDate(this.invoice.created)
					},
					{
						title: 'Due Date:',
						text: formatDate(this.invoice.due_date)
					}
				]
				if (this.invoice.status === 'Paid') {
					arr.push({
						title: 'Paid Date:',
						text: formatDate(this.invoice.paid_date)
					})
				}
				return arr
			},
			invoiceItem() {
				let arr = [{
						title: 'Sub Total',
						text: `$ ${this.invoice.sub_total} `,
						show: true
					},
					{
						title: 'Tax',
						text: `$ ${this.invoice.tax_fee} `,
						show: this.invoice.tax_descripiton
					},
					{
						title: 'Credit',
						text: `$ ${this.invoice.credit} `,
						show: true
					},
					{
						title: 'Total',
						text: `$ ${this.invoice.total} `,
						show: true
					}
				]
				return arr;
			}
		},
		methods: {
			savePDF() {
				$('html,body').animate({scrollTop:0},0);
				html2Canvas(this.$refs.invoice, {
					scale: 2,
				}).then(function(canvas) {
					let contentWidth = canvas.width;
					let contentHeight = canvas.height;
					let pageHeight = contentWidth / 592.28 * 841.89;
					let leftHeight = contentHeight;
					let position = 0;
					let imgWidth = 595.28;
					let imgHeight = 595.28 / contentWidth * contentHeight;
					let pageData = canvas.toDataURL('image/jpeg', 0.5);
					let pdf = new jsPDF('', 'pt', 'a4');
					pdf.internal.scaleFactor = 1.33;
					if (leftHeight < pageHeight) {
						pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);
					} else {
						while (leftHeight > 0) {
							pdf.addImage(pageData, 'JPEG', 20, position + 40, imgWidth, imgHeight)
							leftHeight -= pageHeight;
							position -= 841.89;
							if (leftHeight > 0) {
								pdf.addPage();
							}
						}
					}
					pdf.save(`invoice.pdf`);
				});
			},
			print() {
				$('.invoice').printThis({
					debug: false, // show the iframe for debugging
					importCSS: false, // import parent page css  11
					importStyle: true, // import style tags
					printContainer: true, // print outer container/$.selector
					loadCSS: ['/static/vuetify/vuetify.min.css', '/static/invoice/invoice.css'], // path to additional css file - use an array [] for multiple
					pageTitle: "", // add title to print page
					removeInline: false, // remove inline styles from print elements
					removeInlineSelector: "*", // custom selectors to filter inline styles. removeInline must be true
					printDelay: 1500, // variable print delay
					base: false, // preserve the BASE tag or accept a string for the URL
					formValues: true, // preserve input/form values
					canvas: false, // copy canvas content
					doctypeString: '...', // enter a different doctype for older markup
					removeScripts: false, // remove script tags from print content
					copyTagClasses: false, // copy classes from the html & body tag
				})
			},
			getinvoice() {
				return this.$http.get(`/invoices/${this.invoiceId}/`).then(res => {
					this.invoice = res;
					this.ready = true
				}).catch(e => {
					this.$message.error(e.detail)
				});
			},
			gotopayment() {
				this.$router.push(`/payment/${this.invoiceId}`)
			},
			formatDate
		},
		created() {
			this.invoiceId = this.$route.params.invoiceId;
			this.getinvoice();
		}
	}
</script>

<style lang="scss">
	.invoice-wrapper {
		width: 100%;
		overflow: auto;
		padding-top: 10px;

		.invoice {
			position: relative;
			width: 840px;
			height: auto;
			box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
			margin: 0 auto;
			background: #FFFFFF;
		}

		.logo-part {
			text-align: center;
			padding-top: 40px;
			
		}

		.logo-part img {
			width: 50%;
			margin: 0 auto;
		}

		.logo-part p {
			padding-left: 80px;
			padding-top: 10px;
			color: #FFFFFF;
			font-size: 18px;
			line-height: 20px;
		}
		.content-part {
			padding-left: 30px !important;
		}
		.left-sidebar {
			background: #f3fafc;
			height: 100%;
		}

		.left-sidebar h2 {
			font-size: 22px;
			margin-top: 15px;
			font-weight: 600;
		}

		.left-sidebar p {
			word-wrap: break-word;
			word-spacing: normal;
			font-size: 16px;
		}

		.invoice-content {
			padding-top: 30px;
			overflow: hidden;
			position: relative;
		}

		.invoice-content .pay-status {
			width: 300px;
			height: 35px;
			background: #FF3700;
			position: absolute;
			right: -98px;
			text-align: center;
			top: 35px;
			font-size: 24px;
			line-height: 35px;
			font-weight: 600;
			transform: rotate(45deg);
			color: #FFFFFF;
			font-family: arial;

			&.unpaid {
				background: #FF3700;
			}

			&.paid {
				background: #10c610;
			}

			&.cancelled {
				background: #FF3700;
			}
		}

		.invoice-content h1 {
			font-size: 50px;
			font-family: "Microsoft Himalaya";
			color: #536168;
		}

		.invoice-content h2 {
			font-size: 22px;
			margin-top: 0px;
			font-weight: 600;
			margin-bottom: 5px;
		}

		.invoice-content h2 i {
			float: right;
			margin-left: 10px;
			font-size: 24px;
			line-height: 30px;
			cursor: pointer;
		}

		.paybuttons {
			width: 840px;
			height: 98px;
			margin: 0 auto;
			margin-bottom: 10px;
			padding: 15px 15px;
			box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
			background: #FFFFFF;
		}

		.paybuttons i {
			font-size: 20px;
			line-height: 48px;
			width: 48px;
			text-align: center;
			float: right;
			margin-top: 10px;
			border: solid 1px #dddddd;
			background: #FFFFFF;
			cursor: pointer;
		}

		.confirm {
			padding: 12px 30px;
			border-radius: 2px;
			background: linear-gradient(90deg, #ffad2c, #ff3f45);
			font-size: 18px;
			border: none;
			color: #FFFFFF;
		}

		.confirm:hover {
			background: linear-gradient(90deg, #eca100, #f41c23);
		}

		table {
			width: 100%;
			border: solid 1px #dddddd;
			border-collapse:collapse;
		}

		.table-bordered {
			
		}

		.table-bordered>thead>tr>th,
		.table-bordered>tbody>tr>th,
		.table-bordered>tfoot>tr>th,
		.table-bordered>thead>tr>td,
		.table-bordered>tbody>tr>td,
		.table-bordered>tfoot>tr>td {
			border: 1px solid #ddd;
			padding: 5px 10px;
			text-align: center;
		}

		.table-striped>tbody>tr:nth-of-type(odd) {
			background-color: #f9f9f9;
		}
	}
</style>
